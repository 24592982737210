import styled from 'styled-components';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { testOverviewsHashSelector } from 'modules/uptime/selectors';
import { fetchTestOverviewsAction } from 'modules/uptime';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

interface Props {
  shouldFetch: boolean;
  resetCounter: () => void;
}

const TableTestOverview: React.FC<Props> = ({ shouldFetch, resetCounter }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const type = PaginationType.ADMIN_TEST_OVERVIEW;
  const hash = useSelector(testOverviewsHashSelector);

  const sendRequest = useCallback(
    () => dispatch(fetchTestOverviewsAction()).finally(resetCounter),
    [dispatch, resetCounter]
  );

  useEffect(() => {
    if (shouldFetch) sendRequest();
  }, [shouldFetch, resetCounter, sendRequest]);

  const getFractionColorClass = useCallback((fraction: string) => {
    const [numerator, denominator] = fraction.split('/').map(Number);
    const fractionValue = numerator / denominator;
    if (fractionValue === 1) {
      return 'bg-success';
    } else if (fractionValue < 1) {
      return 'bg-danger';
    }
    return '';
  }, []);

  const renderCell = (value: string) => (
    <td className={classNames('text-center', getFractionColorClass(value || ''))}>{value || '-'}</td>
  );

  return (
    <DataTable paginationType={type} totalAmount={0} sendRequest={sendRequest} containerClassName="border-top-0">
      {Object.keys(hash || {}).map((tenant: string) =>
        Object.keys(hash?.[tenant] || {}).map((portfolio: string, portfolioIndex: number) =>
          hash[tenant][portfolio].map((item, index, arr) => (
            <StyledTR key={tenant + portfolio + item.scenario}>
              {!portfolioIndex && !index && (
                <td rowSpan={Object.values(hash[tenant]).reduce((sum, arr) => sum + arr.length, 0)}>{tenant || '-'}</td>
              )}
              {!index && <td rowSpan={arr.length}>{portfolio || '-'}</td>}

              <td>{item.scenario || '-'}</td>
              {renderCell(item.map)}
              {renderCell(item.data_quality)}
              {renderCell(item.network_loading)}
              {renderCell(item.investment)}
              {renderCell(item.customer)}
              {renderCell(item.n_1)}
              {renderCell(item.co2e)}
              {renderCell(item.setup)}
              {renderCell(item.new_load)}
              {renderCell(item.download_reports)}
              {renderCell(item.maintenance_and_planning)}
              {renderCell(item.reinvestment)}
            </StyledTR>
          ))
        )
      )}
    </DataTable>
  );
};

const StyledTR = styled.tr`
  td {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
`;

export default TableTestOverview;
