import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  meterDataIngestionCountSelector,
  meterDataIngestionItemsSelector,
  tableMeterDataIngestionWaitForDependenciesSelector,
} from 'modules/uptime/selectors';
import { fetchMeterDataIngestionAction } from 'modules/uptime';
import { dateFormat } from '@utiligize/shared/utils';
import { DataTable } from 'components/_common';
import { PaginationType } from 'constants/index';

const TableMeterDataIngestion: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.ADMIN_METER_DATA_INGESTION;

  const items = useSelector(meterDataIngestionItemsSelector);
  const count = useSelector(meterDataIngestionCountSelector);
  const waitForDependencies = useSelector(tableMeterDataIngestionWaitForDependenciesSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchMeterDataIngestionAction(params)).then(
        (action: Shared.ReduxAction<{ meterDataIngestionItems: Uptime.Root['meterDataIngestionItems'] }>) =>
          action.payload?.meterDataIngestionItems
      ),
    [dispatch]
  );

  return (
    <DataTable
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight="calc(100vh - 20vh - 307px)"
      waitForDependencies={waitForDependencies}
    >
      {(items || []).map(item => (
        <tr key={item.timestamp_ingestion}>
          <td className="text-nowrap">
            {item.timestamp_ingestion ? dateFormat(item.timestamp_ingestion, 'L LT') : '-'}
          </td>
          <td>{item.nr_files_ingested ?? '-'}</td>
          <td>{item.file_size_mb ?? '-'}</td>
          <td>{item.ingestion_duration ?? '-'}</td>
          <td>{item.nr_rows ?? '-'}</td>
          <td>{item.nr_meters}</td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableMeterDataIngestion;
