import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { startLoadflowAction } from 'modules/calculations';
import { PaginationType } from 'constants/index';

export const getMiscString = (values: Calculations.NewExecution) => {
  const miscConfig = (
    (['develop', 'staging', 'product'].includes(values.environment!) ? [] : ['environment']).concat([
      'flow_type',
      'portfolio_id',
      'scenario_id',
      'investment_scenario_id',
      'partial_run',
    ]) as (keyof Calculations.NewExecution)[]
  ).reduce(
    (acc, key) => {
      acc[key] = values[key];
      return acc;
    },
    {} as Record<keyof Calculations.NewExecution, any>
  );

  return (
    values.misc ||
    Object.keys(miscConfig)
      .map((key: string) =>
        miscConfig[key as keyof typeof miscConfig] || typeof miscConfig[key as keyof typeof miscConfig] == 'boolean'
          ? `${key}::${miscConfig[key as keyof typeof miscConfig]}`
          : ''
      )
      .filter(Boolean)
      .join(',')
      .replace('flow_type', 'flow')
      .replace('environment', 'env_override')
      .replace('partial_run::true', 'partial_run::True')
      .replace('partial_run::false', 'partial_run::False')
  );
};

const useConfiguredFormik = ({ toggleModal }: { toggleModal: () => void }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.ADMIN_LOADFLOW;
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));
  const initialValues = {
    flow: filters?.flow,
    environment: filters?.environment,
    tenant: filters?.tenant,
    steps: null,
    flow_type: null,
    portfolio_id: null,
    scenario_id: null,
    investment_scenario_id: null,
    partial_run: true,
    misc: '',
  };

  const formik = useFormik<Calculations.NewExecution>({
    initialValues,

    enableReinitialize: true,

    validationSchema: Yup.object().shape({
      tenant: Yup.string().nullable().required("Field can't be empty!"),
      steps: Yup.array().min(1, "Field can't be empty!").required(),
      portfolio_id: Yup.number().nullable().required("Field can't be empty!"),
      scenario_id: Yup.number().nullable().required("Field can't be empty!"),
      investment_scenario_id: Yup.number().nullable().required("Field can't be empty!"),
    }),

    onSubmit: values => {
      return dispatch(startLoadflowAction({ ...values, misc: getMiscString(values) }))
        .then(() => {
          dispatch(
            setPaginationAction({
              type,
              modifier: {
                filters: { ...filters, flow: values.flow, environment: values.environment, tenant: values.tenant },
                offset: 0,
              },
            })
          );
          toggleModal();
        })
        .catch(console.error);
    },
  });

  return formik;
};

export default useConfiguredFormik;
