import { handleActions, combineActions } from 'redux-actions';
import {
  resetReduxStateAction,
  fetchStatusOverviewsAction,
  fetchTestOverviewsAction,
  fetchFunctionalityTestsAction,
  fetchTestHistoryAction,
  fetchDataHistoryAction,
  fetchMeterDataIngestionAction,
} from './actions';

const initialState: Uptime.Root = {
  statusOverviewsHash: {},
  testOverviewsHash: {},
  testOverviewsLastTestRunTimestamp: null,
  functionalityTestsCount: 0,
  functionalityTestsHash: {},
  testHistoryCount: 0,
  testHistoryItems: null,
  dataHistoryCount: 0,
  dataHistoryItems: null,
  meterDataIngestionCount: 0,
  meterDataIngestionItems: null,
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [combineActions(
    resetReduxStateAction,
    fetchStatusOverviewsAction,
    fetchTestOverviewsAction,
    fetchFunctionalityTestsAction,
    fetchTestHistoryAction,
    fetchDataHistoryAction,
    fetchMeterDataIngestionAction
  ) as any]: {
    next: (
      state: Uptime.Root,
      action: Shared.ReduxAction<Partial<Uptime.Root> & { skipStoreUpdate: true | undefined }>
    ): Uptime.Root => {
      if (action.payload.skipStoreUpdate) return state;
      return {
        ...state,
        ...action.payload,
      };
    },
  },
};

export {
  resetReduxStateAction,
  fetchStatusOverviewsAction,
  fetchTestOverviewsAction,
  fetchFunctionalityTestsAction,
  fetchTestHistoryAction,
  fetchDataHistoryAction,
  fetchMeterDataIngestionAction,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
