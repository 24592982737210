import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useAutoFetch } from 'hooks';
import { testOverviewsLastTestRunTimestampSelector } from 'modules/uptime/selectors';
import TableTestOverview from './TableTestOverview';
import { DataTableTemplate } from '@utiligize/shared/components';
import { dateFormat } from '@utiligize/shared/utils';

const ViewTestOverview: React.FC = () => {
  const testOverviewsLastTestRunTimestamp = useSelector(testOverviewsLastTestRunTimestampSelector);
  const { nextUpdateDiff, shouldFetch, resetCounter } = useAutoFetch({ fetchInterval: 5 * 60 * 1000 }); // 5 mins

  return (
    <DataTableTemplate>
      <Row>
        <Col xs="auto" className="ml-auto text-right text-muted">
          <small className="d-block">
            Last test run:{' '}
            <span className="text-bold">
              {testOverviewsLastTestRunTimestamp ? dateFormat(testOverviewsLastTestRunTimestamp, 'lll') : '-'}
            </span>
          </small>
          <small className="d-block">
            Next update in{' '}
            <span style={{ width: '22px', display: 'inline-block', textAlign: 'center' }} className="text-bold">
              {nextUpdateDiff}
            </span>{' '}
            seconds
          </small>
        </Col>
      </Row>
      <TableTestOverview shouldFetch={shouldFetch} resetCounter={resetCounter} />
    </DataTableTemplate>
  );
};

export default ViewTestOverview;
